.pricingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem 0.5rem;
  background: #fff;
  border-radius: 16px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
}

.pricingContainer p {
  font-size: 14px;
  font-weight: 500;
  line-height: 122%;
}

.pricingContainer h2 {
  font-size: 16px;
  color: #27ae60;
  font-weight: 700;
}

.pricingContainer .free {
  text-decoration-line: line-through;
  color: #6a6a6a;
}
